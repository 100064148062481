import React from 'react'
import Layout from '../components/Layout'
import { Button, Container, Grid, List, ListItem } from '@material-ui/core'
import homeStyles from '../styles/home'
import KnowUs from '../components/Image/know-us'
import EyeIcon from '@material-ui/icons/RemoveRedEyeOutlined'
import DocIcon from '@material-ui/icons/DescriptionOutlined'
import GraphIcon from '@material-ui/icons/AssessmentOutlined'

/*import Istoe from '../components/Image/istoe'
import Exame from '../components/Image/exame'
import Estadao from '../components/Image/estadao'
import Canaltech from '../components/Image/canaltech'
import StartSe from '../components/Image/startse'
import LogoDesktop from '../components/Image/logo-white'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'*/

import Carousel from '../components/Carousel'
import SEO from '../components/SEO'
import SouthSystem from '../components/Image/southsytem'
import Security from '../components/Image/security'
import { scrollTo } from '../services/utils'

const IndexPage = () => {
  const classes = homeStyles()

  return (
    <Layout>
      <SEO title="Início" />

      <section className={classes.jumbotron}>
        <div className={classes.jumbotronImage}>
          <KnowUs />
        </div>

        <div className={classes.jumbotronWrapper}>
          <Container maxWidth="xl">
            <p className={classes.jumbotronText}>
              Os melhores<br />
              talentos nos<br />
              melhores ambientes
            </p>

            <Button
              className={classes.knowUs}
              variant="contained"
              color="primary"
              onClick={() => scrollTo('explore', 400)}
            >
              Conheça o nosso trabalho
            </Button>
          </Container>
        </div>
      </section>

      <section id="features">
        <Container className={classes.features} maxWidth="xl">
          <Grid container className={classes.itemFeature}>
            <Grid item xs={12} sm={4}>
              <h6>RÁPIDO & FÁCIL</h6>

              <p>
                Chega de burocracia nos processos seletivos. O Jobii foi pensado em simplicidade e é com esta mentalidade que entregamos o melhor software de recrutamento e seleção do mercado.
              </p>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <h6>QUALIFICAÇÃO</h6>

              <p>
                Através da nossa plataforma, você trabalhará as vagas com base nos profissionais mais qualificados para elas, sem dor de cabeça e sem precisar avaliar CV por CV. Mais produtividade no seu dia-a-dia!
              </p>
            </Grid>

            <Grid item xs={12} sm={4}>
              <h6>NOSSA PLATAFORMA</h6>

              <p>
                Aliamos as melhores tecnologias e experiência de usuário para formatar a nossa plataforma. Com poucos cliques, você alcança o resultado desejado. Vamos iniciar nossa jornada?
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section>
        <Container className={classes.platform} maxWidth="xl">
          <h2>Contrate profissionais de uma maneira mais eficiente</h2>

          <Grid container className={classes.itemPlatform}>
            <Grid item xs={12} sm={4}>
              <h4>1</h4>
              <h6>Descubra</h6>

              <p>
                A plataforma realiza o melhor mapeamento de profissionais de acordo com os inputs e insights gerados pelos gestores, líderes e profissionais de rh.
              </p>

              <div className={classes.iconWrapper}>
                <EyeIcon />
              </div>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <h4>2</h4>
              <h6>Contrate</h6>

              <p>
                Os processos são gerados de acordo com o perfil da vaga, podendo serem customizados de acordo com os principios legais de cada corporação.
              </p>

              <div className={classes.iconWrapper}>
                <DocIcon />
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <h4>3</h4>
              <h6>Acompanhe</h6>

              <p>
              Dashboards, análises de performance e acompanhamentos são feitos de acordo com a recorrência que cada gestor ou profissional de recursos humanos deseja.
              </p>

              <div className={classes.iconWrapper}>
                <GraphIcon />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container className={classes.platformButton} maxWidth="xl">
          <Button
            className={classes.btnMore}
            variant="contained"
            color="primary"
          >
            Saiba Mais
          </Button>
        </Container>
      </section>

      <section className={classes.testimonials} id="explore">
        {/*<Container maxWidth="xl">
          <ul className={classes.listLogos}>
            <li>
              <Exame />
            </li>

            <li>
              <Istoe />
            </li>

            <li>
              <Canaltech />
            </li>

            <li>
              <Estadao />
            </li>

            <li>
              <StartSe />
            </li>
          </ul>
        </Container>*/}

        <h5 className={classes.footerTitle} style={{paddingTop: 42}}>Veja o que nossos parceiros estão comentando</h5>

        <Container>
          <Carousel>
            <div>
              <p className={classes.testimonialText}>
                "Diante de um mercado tão aquecido como o de TI, a utilização da Jobii veio no momento certo para ganharmos agilidade e rapidez na captura de novos profissionais e, mais do que isso, os profissionais certos!"
              </p>

              <p className={classes.testimonialAuthor}>
                Francisco Moreira - SouthSystem
              </p>
            </div>
          </Carousel>
        </Container>
      </section>

      <section className={classes.security}>
        <Container>
          <Grid container>
            <Grid xs={12} sm={5} item>
              <h5 className={classes.sectionTitleAlt}>
                Sigilo e segurança<br /> nos dados sensíveis 
              </h5>

              <p className={classes.securityParagraph}>
                Todos os dados gerados de candidatos e processos seletivos são totalmente sigilosos e protegidos por nós e pela corporação, seguindo os processos legais.
              </p>

              <div className={classes.more}>
                <Button
                  className={classes.btnMore}
                  variant="contained"
                  color="primary"
                >
                  Saiba Mais
                </Button>
              </div>
            </Grid>

            <Grid xs={12} sm={7} className={classes.gridImageSecurity} item>
              <Security />
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className={classes.whoIsUsing}>
        <Container>
          <h5 className={classes.sectionTitle}>Quem utiliza</h5>

          <List>
            <ListItem>
              <a href="https://southsystem.com.br" target="_blank" rel="noopener noreferrer">
                <SouthSystem />
              </a>
            </ListItem>
          </List>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage