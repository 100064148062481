import { makeStyles } from '@material-ui/core/styles'

const sectionTitle = (theme) => ({
  margin: 0,
  padding: '24px 0',
  color: theme.palette.primary.main,
})

const homeStyles = makeStyles(theme => ({
  root: {
  },
  jumbotronText: { 
    color: theme.palette.secondary.main,
    fontSize: 62,
    lineHeight: '80px'
  },
  knowUs: {
    fontSize: 15,
    ...theme.defaultButton,
  },
  jumbotron: {
    position: 'relative',
    padding: '24px 8px 32px 8px'
  },
  jumbotronImage: {
    position: 'absolute',
    right: 0,
    top: '5%'
  },
  features: {
    marginTop: 50,

    '& p': {
      fontSize: 16,
      padding: '0 12%',
    }
  },
  itemFeature: {
    '& h6': {
      color: theme.palette.primary.main,
      fontSize: 16,
      textAlign: 'center'
    }
  },
  platform: {
    '& h2': {
      position: 'relative',
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 32,
      fontFamily: 'Exo-Thin',
      marginTop: 50,
      paddingTop: 50,

      '&:before': {
        content: '""',
        top: 0,
        left: '5%',
        width: '90%',
        height: 1,
        background: theme.palette.primary.main,
        position: 'absolute'
      }
    },

    '& h4': {
      fontFamily: 'Exo',
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 38
    },

    '& h6': {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 18,
      fontFamily: 'Exo-Thin',
    },

    '& p': {
      fontSize: 16,
      padding: '0 18%',
      textAlign: 'center'
    }
  },
  iconWrapper: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: 48,

    '& svg': {
      width: 70,
      height: 70
    }
  },
  platformButton: {
    textAlign: 'center',
    margin: '32px auto'
  },
  btnMore: {
    borderRadius: 50,
    padding: '11px 38px',
    boxShadow: 'none'
  },
  testimonials: {
    background: theme.palette.primary.main,
    paddingBottom: 70,
    marginTop: 90
  },
  listLogos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '40px 20px',

    '& li': {
      display: 'inline'
    }
  },
  footerTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 26
  },
  testimonialText: {
    textShadow: '2px 2px 5px rgba(0,0,0,0.65)',
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    padding: '0 10%'
  },
  testimonialAuthor: {
    color: '#fff',
    fontSize: 26,
    textAlign: 'center',
    marginTop: 48
  },
  wrapperCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  whoIsUsing: {
    background: '#050505',
    padding: '8px 0 4%',
    
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& li': {
      width: 'auto',
      '-webkit-filter': 'grayscale(100%)',
      filter: 'grayscale(100%)',
    }
  },
  sectionTitle: {
    fontSize: '24px',
    textAlign: 'center',
    ...sectionTitle(theme)
  },
  sectionTitleAlt: {
    fontSize: '36px',
    ...sectionTitle(theme)
  },
  gridImageSecurity: {
    textAlign: 'right'
  },
  securityParagraph: {
    marginBottom: 40,
    fontSize: '16px'
  },
  security: {
    padding: '6% 0',
    display: 'flex',
    alignItems: 'center',

    '& .gatsby-image-wrapper': {
      maxWidth: '100%'
    }
  },
  '@media only screen and (max-width: 580px)': {
    jumbotronImage: {
      position: 'relative',
      opacity: 0.3,
      marginRight: -8,
      marginLeft: -8,

      '& .gatsby-image-wrapper': {
        maxWidth: '100%'
      }
    },
    more: {
      textAlign: 'center'
    },
    jumbotronWrapper: {
      position: 'absolute',
      top: 0,

      '& img': {
        maxWidth: '100%'
      },
    },
    jumbotronText: {
      fontSize: '42px'
    },
    footerTitle: {
      fontSize: 23,
    },
    testimonialText: {
      fontSize: 23,
    },
    testimonialAuthor: {
      fontSize: 23,
    },
    jumbotron: {
      paddingBottom: 0
    },
    features: {
      marginTop: 0,

      '& p': {
        padding: '0 6%',
        textAlign: 'center'
      }
    },
    platform: {
      '& p': {
        padding: '0 6%',
        textAlign: 'center'
      }
    },
    sectionTitleAlt: {
      textAlign: 'center'
    },
    securityParagraph: {
      textAlign: 'center'
    }
  }
}));

export default homeStyles