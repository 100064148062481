import React from 'react'
import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'

const Carousel = ({ children }) => {
  const settings = {
    dots: true,
    dotsClass: 'slick-dots jobii-slick-dots',
    arrows: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500
  };

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

Carousel.propTypes = {
}

export default Carousel